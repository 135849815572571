import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../Spinner';

function DefaultLayout() {
  const sidebarToggle = useSelector((state) => state.sidebarToggle);

  const handleToggleSideBarMenu = () => {
    const body = document.body;
    const isMobileTablet = window.innerWidth <= 768;
    if (body.getAttribute('data-sidebar') == 'default' && isMobileTablet) {
      body.setAttribute('data-sidebar', 'hidden');
    } else {
      body.setAttribute('data-sidebar', 'default');
    }
  };

  return (
    <>
      <div>
        <div id="app-layout">
          <Header onToggleSideBar={handleToggleSideBarMenu} />

          {sidebarToggle && <SideBar />}

          <div className="content-page">
            <Suspense fallback={<Spinner color="text-primary" isPage={true} />}>
              <Outlet />
            </Suspense>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default DefaultLayout;
