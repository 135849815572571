import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Menu,
  Bell,
  Download,
  User,
  Youtube,
  Maximize,
  LogOut,
  Settings,
} from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../service';
import { adminAccess } from '../../utils';
import { useEffect, useState } from 'react';

function Header({ onToggleSideBar }) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const _user = useSelector((state) => state.registeredUser);
  const statsData = useSelector((state) => state.dashboardEvents);
  const dashboardEvents = useSelector((state) => state.dashboardEvents.events);
  
  const [showMenuIcon, setShowMenuIcon] = useState(window.innerWidth);

  const [hasAccess, setHasAccess] = useState(false);

  const userImage = _user?.image || user?.image;

  const handleDashboardSearch = (e) => {
    e.preventDefault();

    if (e.target.value === '') {
      dispatch({
        type: 'SET_DASHBOARD_EVENTS',
        dashboardEvents: { ...statsData, events: dashboardEvents },
      });
      return;
    }

    dispatch({
      type: 'SET_DASHBOARD_EVENTS',
      dashboardEvents: {
        ...statsData,
        events: dashboardEvents.filter((event) =>
          event.name.includes(e.target.value)
        ),
      },
    });
  };

  const handleShowMenuIcon = () => {
    setShowMenuIcon(window.innerWidth);

    if (window.innerWidth <= 768) {
      setShowMenuIcon(true);
    } else {
      setShowMenuIcon(false);
    }
  };

  useEffect(() => {
    setHasAccess(adminAccess.includes(user?.role));
    handleShowMenuIcon();

    window.addEventListener('resize', handleShowMenuIcon);

    return () => {
      window.removeEventListener('resize', handleShowMenuIcon);
    }
  }, [user, setHasAccess]);

  const handleLogout = async () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    try {
      await logout();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="topbar-custom">
        <div className="container-xxl">
          <div className="d-flex justify-content-between">
            <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
              <li>
                <button
                  className="button-toggle-menu nav-link ps-0"
                  onClick={onToggleSideBar}
                >
                  {showMenuIcon && <Menu />}
                </button>
              </li>
              <li className="d-none d-lg-block">
                <div className="position-relative topbar-search">
                  <input
                    type="text"
                    className="form-control bg-light bg-opacity-75 border-light ps-4"
                    placeholder="Search..."
                    onChange={handleDashboardSearch}
                  />
                  {/* <i className="mdi mdi-magnify fs-16 position-absolute text-muted top-50 translate-middle-y ms-2"></i> */}
                </div>
              </li>
              {/* <li className="d-none d-lg-block">
                <Maximize className="mdi mdi-magnify fs-16 position-absolute text-muted top-50 translate-middle-y ms-2" />
              </li> */}
            </ul>

            <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
              <li className="d-flex d-sm-flex">
                {/* TODO: */}
                {/* <span className="badge text-bg-success">Involvement</span> */}
              </li>

              {false && (
                <li className="dropdown notification-list topbar-dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    <Bell />
                    {/* <span className="badge bg-danger rounded-circle noti-icon-badge">
                    9
                  </span> */}
                  </a>
                  <div className="dropdown-menu dropdown-menu-end dropdown-lg">
                    <div className="dropdown-item noti-title">
                      <h5 className="m-0">
                        <span className="float-end">
                          <a href="" className="text-dark">
                            <small>Clear All</small>
                          </a>
                        </span>
                        Notification
                      </h5>
                    </div>

                    <div className="noti-scroll" data-simplebar>
                      <a
                        href="#;"
                        className="dropdown-item notify-item text-muted link-primary active"
                      >
                        <div className="notify-icon">
                          <img
                            src="../assets/images/logo-1.png"
                            className="img-fluid rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="notify-details">Carl Steadham</p>
                          <small className="text-muted">5 min ago</small>
                        </div>
                        <p className="mb-0 user-msg">
                          <small className="fs-14">
                            Completed{' '}
                            <span className="text-reset">
                              Improve workflow in Figma
                            </span>
                          </small>
                        </p>
                      </a>

                      <a
                        href="#;"
                        className="dropdown-item notify-item text-muted link-primary"
                      >
                        <div className="notify-icon">
                          <img
                            src="../assets/images/logo-1.png"
                            className="img-fluid rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="notify-content">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="notify-details">Olivia McGuire</p>
                            <small className="text-muted">1 min ago</small>
                          </div>

                          <div className="d-flex mt-2 align-items-center">
                            <div className="notify-sub-icon">
                              {/* <i className="mdi mdi-download-box text-dark"></i> */}
                              <Download />
                            </div>

                            <div>
                              <p className="notify-details mb-0">
                                dark-themes.zip
                              </p>
                              <small className="text-muted">2.4 MB</small>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        href="#;"
                        className="dropdown-item notify-item text-muted link-primary"
                      >
                        <div className="notify-icon">
                          <img
                            src="assets/images/logo-1.png"
                            className="img-fluid rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="notify-content">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="notify-details">Travis Williams</p>
                            <small className="text-muted">7 min ago</small>
                          </div>
                          <p className="noti-mentioned p-2 rounded-2 mb-0 mt-2">
                            <span className="text-primary">@Patryk</span> Please
                            make sure that you're....
                          </p>
                        </div>
                      </a>

                      <a
                        href="#;"
                        className="dropdown-item notify-item text-muted link-primary"
                      >
                        <div className="notify-icon">
                          <img
                            src="assets/images/logo-1.png"
                            className="img-fluid rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="notify-details">Violette Lasky</p>
                          <small className="text-muted">5 min ago</small>
                        </div>
                        <p className="mb-0 user-msg">
                          <small className="fs-14">
                            Completed{' '}
                            <span className="text-reset">
                              Create new components
                            </span>
                          </small>
                        </p>
                      </a>

                      <a
                        href="#;"
                        className="dropdown-item notify-item text-muted link-primary"
                      >
                        <div className="notify-icon">
                          <img
                            src="assets/images/logo-1.png"
                            className="img-fluid rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="notify-details">Ralph Edwards</p>
                          <small className="text-muted">5 min ago</small>
                        </div>
                        <p className="mb-0 user-msg">
                          <small className="fs-14">
                            Completed{' '}
                            <span className="text-reset">
                              Improve workflow in React
                            </span>
                          </small>
                        </p>
                      </a>

                      <a
                        href="#;"
                        className="dropdown-item notify-item text-muted link-primary"
                      >
                        <div className="notify-icon">
                          <img
                            src="../assets/images/logo-1.png"
                            className="img-fluid rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="notify-content">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="notify-details">Jocab jones</p>
                            <small className="text-muted">7 min ago</small>
                          </div>
                          <p className="noti-mentioned p-2 rounded-2 mb-0 mt-2">
                            <span className="text-reset">@Patryk</span> Please
                            make sure that you're....
                          </p>
                        </div>
                      </a>
                    </div>

                    <a
                      href="#;"
                      className="dropdown-item text-center text-primary notify-item notify-all"
                    >
                      View all
                      <i className="fe-arrow-right"></i>
                    </a>
                  </div>
                </li>
              )}

              <li className="dropdown notification-list topbar-dropdown">
                <a
                  className="nav-link dropdown-toggle nav-user me-0"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userImage || '../assets/images/logo-1.png'}
                    alt={user?.first_name}
                    className="rounded-circle"
                  />
                  <span className="pro-user-name ms-1">{user?.first_name}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                  <Link to="profile" className="dropdown-item notify-item">
                    <User size={16} />
                    &nbsp;&nbsp;
                    <span>Account</span>
                  </Link>

                  {hasAccess && <Link to="people" className="dropdown-item notify-item">
                    <Settings size={16} />
                    &nbsp;&nbsp;
                    <span>Settings</span>
                  </Link>}

                  <Link to="files" className="dropdown-item notify-item">
                    <Youtube size={16} />
                    &nbsp;&nbsp;
                    <span>Resource</span>
                  </Link>

                  <div className="dropdown-divider"></div>

                  <Link
                    className="dropdown-item notify-item"
                    onClick={handleLogout}
                    to="/"
                  >
                    <LogOut size={16} />
                    &nbsp;&nbsp;
                    <span>Logout</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

Header.protoTypes = {
  onToggleSideBar: PropTypes.func,
};
