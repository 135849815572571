import PropTypes from 'prop-types';

function Spinner({ color, isPage = false }) {
  const spinnerSize = isPage ? 'spinner-border-lg' : 'spinner-border-sm';
  const spinnerClass = `spinner-border ${spinnerSize} ${color}`;
  return (
    <>
      <span className={spinnerClass} aria-hidden="true"></span>
      <span className="visually-hidden" role="status">
        Loading...
      </span>
    </>
  );
}

export default Spinner;

Spinner.propTypes = {
  color: PropTypes.string.isRequired,
  isPage: PropTypes.bool,
};
