function Footer() {
    return (
      <>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col fs-13 text-muted text-center">
                &copy; {new Date().getFullYear()} - Made with{' '}
                <span className="mdi mdi-heart text-danger"></span> by{' '}
                <a href="#!" className="text-reset fw-semibold">
                  Cyclux
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
}

export default Footer;