import { lazy } from 'react';
import DefaultLayout from './components/layout/DefaultLayout.jsx';
import Authenticate from './components/layout/AuthLayout.jsx';
import Error404 from './pages/Error404.jsx';
import {
  HashRouter,
  Route,
  Routes,
} from 'react-router-dom';

const Dashboard = lazy(() => import('./pages/Dashboard.jsx'));
const Forgot = lazy(() => import('./pages/Forgot.jsx'));
const Reset = lazy(() => import('./pages/Reset.jsx'));
const Members = lazy(() => import('./pages/Members.jsx'));
const CalendarPage = lazy(() => import('./pages/Calendar.jsx'));
const Messages = lazy(() => import('./pages/Messages.jsx'));
const Groups = lazy(() => import('./pages/Groups.jsx'));
const Giving = lazy(() => import('./pages/Giving.jsx'));
const Finance = lazy(() => import('./pages/Finance.jsx'));
const Store = lazy(() => import('./pages/Store.jsx'));
const Files = lazy(() => import('./pages/Files.jsx'));
const Sermons = lazy(() => import('./pages/Sermons.jsx'));
const Library = lazy(() => import('./pages/Library.jsx'));
const Gallery = lazy(() => import('./pages/Gallery.jsx'));
const Resources = lazy(() => import('./pages/Resources.jsx'));
const People = lazy(() => import('./pages/People.jsx'));
const Report = lazy(() => import('./pages/Report.jsx'));
const Profile = lazy(() => import('./pages/Profile.jsx'));
const AuditLog = lazy(() => import('./pages/AuditLog.jsx'));
const Events = lazy(() => import('./pages/Events.jsx'));
const Login = lazy(() => import('./pages/Login.jsx'));
const Register = lazy(() => import('./pages/Register.jsx'));
const Attendance = lazy(() => import('./pages/Attendance.jsx'));
const BlogPost = lazy(() => import('./pages/BlogPost.jsx'));
const PostExpansion = lazy(() => import('./components/blog/PostExpansion.jsx'));

const routes = [
  { path: 'dashboard', name: 'Dashboard', element: <Dashboard /> },
  { path: 'calendar', exact: true, element: <CalendarPage /> },
  { path: 'member', exact: true, element: <Members /> },
  { path: 'message', exact: true, element: <Messages /> },
  { path: 'group', exact: true, element: <Groups /> },
  { path: 'event', exact: true, element: <Events /> },
  { path: 'giving', exact: true, element: <Giving /> },
  { path: 'finance', exact: true, element: <Finance /> },
  { path: 'store', exact: true, element: <Store /> },
  { path: 'blog', exact: true, element: <BlogPost /> },
  { path: 'post', exact: true, element: <PostExpansion /> },

  { path: 'files', exact: true, element: <Files /> },
  { path: 'sermons', exact: true, element: <Sermons /> },
  { path: 'library', exact: true, element: <Library /> },
  { path: 'gallery', exact: true, element: <Gallery /> },
  { path: 'resource', exact: true, element: <Resources /> },

  { path: 'people', exact: true, element: <People /> },
  { path: 'report', exact: true, element: <Report /> },
  { path: 'profile', exact: true, element: <Profile /> },
  { path: 'auditlog', exact: true, element: <AuditLog /> },
  { path: 'attendees', exact: true, element: <Attendance /> },
];

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Authenticate />}>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
        </Route>
        <Route path="/cyclux" element={<DefaultLayout />}>
          {routes.map((route, index) => {
            return route.exact ? (
              <Route key={index} path={route.path} element={route.element} />
            ) : (
              <Route key={index} path={route.path} element={route.element} />
            );
          })}
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
