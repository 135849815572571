import { jsPDF } from 'jspdf';

function convertArrayOfObjectsToCSV(array) {
  let result;
  
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array, name = 'export') {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = `${name}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const mapMembershipWithDefault = (memberships) => {
  return memberships.map((membership) => {
    return {
      ...membership,
      email: membership.email === null ? 'cyclux@domain.com' : membership.email,
    };
  });
};

const resourceType = {
  Audio: 'audio',
  Blog: 'blog',
  Image: 'image',
  Video: 'video',
};

const roles = [
  'Member',
  'Admin',
  'Super admin',
  'Church worker',
  'Data entry personnel',
  'Pastor',
  'Usher',
  'Accountant',
];

const roleObj = {
  member: 'member',
  admin: 'admin',
  superadmin: 'super admin',
  churchWorker: 'church worker',
  dataEntryPersonnel: 'data entry personnel',
  pastor: 'pastor',
  usher: 'usher',
  accountant: 'accountant',
};

const adminAccess = ['super admin', 'admin', 'church worker', 'pastor'];
const clerkAccess = ['data entry personnel', 'accountant'];
const memberAccess = ['member', 'usher'];

const joinStringWithHyphen = (str) => {
  if (!str) return '';
  return str.split(' ').join('-');
};

const generatePDF = (docs, type) => {
  const doc = new jsPDF();
  // {
  //   orientation: 'landscape',
  //   unit: 'in',
  //   format: [4, 2],
  // }

  switch (type) {
    case 'events':
      doc.text('events', 10, 10);
      docs.forEach((event) => {
        doc.text(
          `Title: ${event.name}, Description: ${event.description}, Start Date: ${event.date}, Start Time: ${event.start_time}, End Time: ${event.end_time}`,
          10,
          10
          // { align: 'left'},
        );
        doc.save();
      });

    case 'users':
      doc.text('users', 10, 10);
      docs.forEach((user) => {
        doc.text(
          `Firstname: ${user.first_name}, Lastname: ${user.last_name}, Email: ${user.email}, Phone number: ${user.phone}, Date of Birth: ${user.dob}`,
          10,
          10
          // { align: 'left'},
        );
        doc.save();
      });
      break;
    case 'memberships':
      doc.text('members', 10, 10);
      docs.forEach((user) => {
        doc.text(
          `Firstname: ${user.first_name}, Lastname: ${user.last_name}, Email: ${user.email}, Phone number: ${user.phone}, Date of Birth: ${user.dob}`,
          10,
          10
          // { align: 'left'},
        );
        doc.save();
      });
      break;
    case 'attendees':
      doc.text('attendees', 10, 10);
      docs.forEach((attendee) => {
        doc.text(
          `Firstname: ${attendee.firstname}, Lastname: ${attendee.lastname}, Email: ${attendee.email}, Phone number: ${attendee.phone}, Event: ${attendee.event}`,
          10,
          10
          // { align: 'left'},
        );
        doc.save();
      });
      break;
    case 'resources':
      doc.text('resources', 10, 10);
      docs.forEach((resource) => {
        doc.text(
          `Title: ${resource.title}, Description: ${resource?.description}, Type: ${resource.type}, Link: ${resource?.url || resource?.image}`,
          10,
          10
          // { align: 'left'},
        );
        doc.save();
      });
      break;

    default:
      break;
  }
};

const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol)
    : '0';
};

const GENDER = ['Female', 'Male'];

export {
  downloadCSV,
  mapMembershipWithDefault,
  resourceType,
  roles,
  roleObj,
  joinStringWithHyphen,
  generatePDF,
  adminAccess,
  clerkAccess,
  memberAccess,
  nFormatter,
  GENDER,
};
