import { Link } from 'react-router-dom';
import navigation from '../../_nav';
import SideBarNav from './SideBarNav';

function SideBar() {

    return (
      <>
        <div className="app-sidebar-menu">
          <div className="h-100" data-simplebar>
            <div id="sidebar-menu">
              <div className="logo-box">
                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src="../assets/images/logo-3.png" alt="logo" height="40" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="../assets/images/logo-3.png"
                      alt="logo"
                      height="70"
                    />
                  </span>
                </Link>
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src="../assets/images/logo-2.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src="../assets/images/logo-2.png" alt="" height="24" />
                  </span>
                </Link>
              </div>

              <ul id="side-menu">
                <SideBarNav items={navigation}/>
              </ul>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </>
    );
}

export default SideBar;