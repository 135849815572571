import { Link } from "react-router-dom";

function Error404() {
  return (
    <>
      <div data-menu-color="light" data-sidebar="default">
        <div className="maintenance-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="text-center">
                  <div className="mb-5 text-center">
                    <a href="index.html" className="auth-logo">
                      <img
                        src="../assets/images/logo-1.png"
                        alt="logo-dark"
                        className="mx-auto"
                        height="28"
                      />
                    </a>
                  </div>

                  <div className="maintenance-img">
                    <img
                      src="../assets/images/svg/404-error.svg"
                      className="img-fluid"
                      alt="coming-soon"
                    />
                  </div>

                  <div className="text-center">
                    <h3 className="mt-5 fw-semibold text-black text-capitalize">
                      Oops!, Page Not Found
                    </h3>
                    <p className="text-muted">
                      This pages you are trying to access does not exits or has
                      been moved. <br /> Try going back to our homepage.
                    </p>
                  </div>

                  <Link className="btn btn-primary mt-3 me-1" to="/">
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error404;
