import { legacy_createStore as createStore } from "redux";

const INITIAL_STATE = {
	sidebarToggle: true,
	memberToggle: false,
	eventToggle: false,
	resourceToggle: false,
	userToggle: false,
	loadingPDF: false,
	loadingImport: false,
	loadingExport: false,
	member: {
		data: [],
		member: {},
		isEdit: false,
	},
	event: {
		data: [],
		event: {},
		isEdit: false,
	},
	message: {
		data: [],
		message: {},
		isEdit: false,
	},
	resource: {
		data: [],
		resource: {},
		isEdit: false,
	},
	user: {
		data: [],
		user: {},
		isEdit: false,
	},
	sendMessage: {
		members: [],
		attendees: [],
	},
	dashboardEvents: {
		events: [],
		event: {},
	},
	dashboardStats: {
		membersCount: 0,
		attendeesCount: 0,
		eventsCount: 0,
		usersCount: 0,
	},
	attendee: {
		data: [],
		attendee: {},
		isEdit: false,
	},
	audios: [],
	images: [],
	videos: [],
	messageEvents: [],
	calendarEvents: [],
	registeredUser: {},
	attendeesStats: {
		counts: {
			today_attendees: 0,
			yesterday_attendees: 0,
			this_month_attendees: 0,
			last_month_attendees: 0,
			this_quarter_attendees: 0,
			last_quarter_attendees: 0,
			this_year_attendees: 0,
			last_year_attendees: 0,
		},
		difference: {
			today: 0,
			month: 0,
			quarter: 0,
			year: 0,
		},
		trends: {
			today: "",
			month: "",
			quarter: "",
			year: "",
		},
	},
	dashboardBlog: {
		blogs: [],
		blog: {},
	},
};

const changeState = (state = INITIAL_STATE, { type, ...rest }) => {
	switch (type) {
    case 'SET_MEMBERSHIPS':
      return { ...state, ...rest };
    case 'SET_SIDEBAR_TOGGLE':
      return { ...state, ...rest };
    case 'SET_EDIT_MEMBERSHIP':
      return { ...state, ...rest };
    case 'SET_EVENTS':
      return { ...state, ...rest };
    case 'SET_EDIT_EVENT':
      return { ...state, ...rest };
    case 'SET_MESSAGES':
      return { ...state, ...rest };
    case 'SET_EDIT_MESSAGE':
      return { ...state, ...rest };
    case 'SET_RESOURCES':
      return { ...state, ...rest };
    case 'SET_EDIT_RESOURCE':
      return { ...state, ...rest };
    case 'SET_VIDEOS':
      return { ...state, ...rest };
    case 'SET_IMAGES':
      return { ...state, ...rest };
    case 'SET_AUDIOS':
      return { ...state, ...rest };
    case 'SET_USERS':
      return { ...state, ...rest };
    case 'SET_EDIT_USER':
      return { ...state, ...rest };
    case 'SET_DASHBOARD_EVENTS':
      return { ...state, ...rest };
    case 'SET_CALENDAR_EVENTS':
      return { ...state, ...rest };
    case 'SET_MESSAGES':
      return { ...state, ...rest };
    case 'SET_SEND_MESSAGE':
      return { ...state, ...rest };
    case 'SET_DASHBOARD_STATISTICS':
      return { ...state, ...rest };
    case 'SET_USER':
      return { ...state, ...rest };
    case 'SET_ATTENDEES':
      return { ...state, ...rest };
    case 'SET_EDIT_ATTENDEE':
      return { ...state, ...rest };
    case 'SET_ATTENDEES_STATISTICS':
      return { ...state, ...rest };
    case 'SET_DASHBOARD_BLOGS':
      return { ...state, ...rest };
    case 'SET_ADD_MEMBER':
      return { ...state, ...rest };
    case 'SET_ADD_EVENT':
      return { ...state, ...rest };
    case 'SET_ADD_RESOURCE':
      return { ...state, ...rest };
    case 'SET_ADD_USER':
      return { ...state, ...rest };
    case 'SET_PDF_LOADING':
      return { ...state, ...rest };
    case 'SET_IMPORT_LOADING':
      return { ...state, ...rest };
    case 'SET_EXPORT_LOADING':
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);

export default store;
