import axios from 'axios';

const baseUrl = import.meta.env.VITE_BASE_URL;
const version = import.meta.env.VITE_VERSION;
const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));
const headers = {
  Authorization: `Bearer ${token}`,
};

const fetchBranches = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/register/data`);
  } catch (error) {
    throw error;
  }
};

const registerUser = async ({
  firstname,
  lastname,
  phoneNumber,
  email,
  password,
  terms,
  dob,
  branch,
  role,
  gender,
  image,
}) => {
  let userRole, userImage, userDob;
  if (dob) userDob = dob;

  if (role) userRole = role;

  if (image) userImage = image;

  try {
    return await axios.postForm(`${baseUrl}/${version}/register`, {
      first_name: firstname,
      last_name: lastname,
      phone: phoneNumber,
      email,
      password,
      terms,
      dob: userDob,
      branch_id: branch,
      role: userRole,
      gender,
      image: userImage,
    });
  } catch (error) {
    throw error;
  }
};

const login = async ({ email, password }) => {
  try {
    return await axios.post(`${baseUrl}/${version}/login`, {
      email,
      password,
    });
  } catch (error) {
    throw error;
  }
};

const createMember = async ({
  first_name,
  last_name,
  phone,
  email,
  dob,
  gender,
  branch,
  group,
  location,
  country,
  image_url,
}) => {
  let image;
  if (image_url) image = image_url;

  try {
    return await axios.postForm(
      `${baseUrl}/${version}/data-entry/memberships`,
      {
        first_name,
        last_name,
        phone,
        email,
        dob,
        gender,
        group_id: Number(group),
        branch_id: Number(branch),
        country_id: Number(country),
        location: location,
        image_url: image,
      },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const fetchMemberships = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/data-entry/memberships`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const createEvent = async ({
  title,
  description,
  startDate,
  endDate,
  startTime,
  endTime,
  event_image,
}) => {
  let eventPoster;
  if (event_image) eventPoster = event_image;

  try {
    return await axios.postForm(
      `${baseUrl}/${version}/data-entry/events`,
      {
        title,
        description,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        event_image: eventPoster,
      },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const fetchEvents = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/data-entry/events`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const createMessage = async (message) => {
  try {
    return await axios.postForm(`${baseUrl}/${version}/send/message`, message, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const fetchMessages = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/messages`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const makeDonation = async ({ amount, description }) => {
  let purpose;
  if (description) purpose = description;

  try {
    return await axios.post(
      `${baseUrl}/${version}/make/donation`,
      {
        amount,
        description: purpose,
      },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const createResource = async (resource) => {
  
  try {
    return await axios.postForm(
      `${baseUrl}/${version}/resources/create`,
      resource,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const fetchResources = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/resources/list`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const fetchCountries = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/countries`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const fetchStates = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/states`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const createCountry = async ({ name }) => {
  try {
    return await axios.post(
      `${baseUrl}/${version}/countries`,
      {
        name,
      },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const createState = async ({ name, country_id }) => {
  try {
    return await axios.post(
      `${baseUrl}/${version}/states`,
      {
        name,
        country_id,
      },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const createBranch = async (branchData) => {
  
  try {
    return await axios.post(
      `${baseUrl}/${version}/branches`,
      branchData,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const changePassword = async (user, newPassword) => {
  try {
    return await axios.put(
      `${baseUrl}/${version}/users/${user}/change-password`,
      {
        password: newPassword,
        password_confirmation: newPassword,
      },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const changeRole = async ({ email, role }) => {
  try {
    return await axios.put(
      `${baseUrl}/${version}/users/${email}/change-role`,
      {
        role,
      },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const emailVerification = async ({ email }) => {
  try {
    return await axios.post(
      `${baseUrl}/${version}/users/email/verification`,
      { email },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const resetPassword = async ({ email, newPassword }) => {
  try {
    return await axios.post(
      `${baseUrl}/${version}/users/${email}/reset-password`,
      { password: newPassword },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const fetchUsers = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/register/users/list`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const editProfile = async (updateUser) => {
  try {
    return await axios.postForm(
      `${baseUrl}/${version}/users/user/profile/edit`,
      updateUser,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const editUserRecord = async (user, updateUser) => {
  try {
    return await axios.postForm(
      `${baseUrl}/${version}/users/user/edit/${user}`,
      updateUser,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const fetchDashboardData = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/dashboard/overview`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const fetchDashboardStatsData = async () => {
  try {
    return await axios.get(
      `${baseUrl}/${version}/dashboard/statistics/overview`,
      {
        headers,
      }
    );
  } catch (error) {
    throw error;
  }
};

const importCsv = async (csv) => {
  try {
    return await axios.postForm(
      `${baseUrl}/${version}/membership/imports`,
      { file: csv, branch_id: user.branch_id },
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const updateMembership = async (membershipId, updateMembership) => {
  try {
    return await axios.put(
      `${baseUrl}/${version}/data-entry/memberships/${membershipId}`,
      updateMembership,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const updateMembershipImage = async (membershipId, updateMembershipImage) => {
  try {
    return await axios.postForm(
      `${baseUrl}/${version}/data-entry/memberships/${membershipId}/image-edit`,
      updateMembershipImage,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const updateEvent = async (eventId, updateEvent) => {
  try {
    return await axios.put(
      `${baseUrl}/${version}/data-entry/events/${eventId}`,
      updateEvent,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const updateEventImage = async (EventId, updateEventImage) => {
  try {
    return await axios.postForm(
      `${baseUrl}/${version}/data-entry/events/${EventId}/image-edit`,
      updateEventImage,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const updateMessage = async (messageId, updateMessage) => {
  try {
    return await axios.put(
      `${baseUrl}/${version}/messages/${messageId}`,
      updateMessage,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const updateResource = async (resourceId, updateResource) => {
  try {
    return await axios.postForm(
      `${baseUrl}/${version}/resources/${resourceId}/edit`,
      updateResource,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (userId) => {
  try {
    return await axios.delete(`${baseUrl}/${version}/users/delete/${userId}`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const deleteMembership = async (membershipId, updateMembership) => {
  try {
    return await axios.delete(
      `${baseUrl}/${version}/data-entry/memberships/${membershipId}`,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const deleteEvent = async (eventId, updateEvent) => {
  try {
    return await axios.delete(
      `${baseUrl}/${version}/data-entry/events/${eventId}`,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const deleteMessage = async (messageId, updateMessage) => {
  try {
    return await axios.delete(`${baseUrl}/${version}/messages/${messageId}`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const deleteResource = async (resourceId) => {
  try {
    return await axios.delete(
      `${baseUrl}/${version}/resources/${resourceId}/delete`,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const createEventAttendee = async (attendee) => {
  try {
    return await axios.post(
      `${baseUrl}/${version}/attendees/event/register`,
      attendee,
      { headers }
    );
  } catch (error) {
    throw error;
  }
};

const createGenerationalGroup = async (group) => {
  try {
    return await axios.post(`${baseUrl}/${version}/groups`, group, { headers });
  } catch (error) {
    throw error;
  }
};

const fetchGenerationalGroup = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/groups`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const fetchAttendees = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/attendees`, {
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const fetchAttendeesDashboardOverview = async () => {
  try {
    return await axios.get(
      `${baseUrl}/${version}/attendees/event/dashboard/overview`,
      {
        headers,
      }
    );
  } catch (error) {
    throw error;
  }
};

const downloadMembershipTemplate = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/membership/exports`, {
      headers,
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};

const downloadUsersPDF = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/users/generate/pdf`, {
      headers,
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};

const downloadMembershipsPDF = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/memberships/generate/pdf`, {
      headers,
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};

const downloadEventsPDF = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/events/generate/pdf`, {
      headers,
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};

const downloadResourcesPDF = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/resources/generate/pdf`, {
      headers,
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};

const downloadEventAttendeesPDF = async () => {
  try {
    return await axios.get(`${baseUrl}/${version}/attendees/generate/pdf`, {
      headers,
      responseType: 'blob',
    });
  } catch (error) {
    throw error;
  }
};

const logout = async () => {
  try {
    return await axios.post(`${baseUrl}/${version}/logout`, {}, { headers });
  } catch (error) {
    throw error;
  }
};

export {
  fetchBranches,
  registerUser,
  login,
  createMember,
  fetchMemberships,
  createEvent,
  fetchEvents,
  createMessage,
  fetchMessages,
  makeDonation,
  createResource,
  fetchResources,
  fetchCountries,
  fetchStates,
  createCountry,
  createState,
  createBranch,
  changePassword,
  changeRole,
  emailVerification,
  resetPassword,
  fetchUsers,
  editProfile,
  editUserRecord,
  fetchDashboardData,
  fetchDashboardStatsData,
  importCsv,
  updateMembership,
  updateMembershipImage,
  updateEvent,
  updateEventImage,
  updateMessage,
  updateResource,
  deleteUser,
  deleteMembership,
  deleteEvent,
  deleteMessage,
  deleteResource,
  createEventAttendee,
  createGenerationalGroup,
  fetchGenerationalGroup,
  fetchAttendees,
  fetchAttendeesDashboardOverview,
  downloadMembershipTemplate,
  downloadUsersPDF,
  downloadMembershipsPDF,
  downloadEventsPDF,
  downloadResourcesPDF,
  downloadEventAttendeesPDF,
  logout,
};
