import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Spinner from '../Spinner';

function AuthLayout() {
  return (
    <>
      <div className="bg-white">
        <div className="account-page">
          <div className="container-fluid p-0">
            <div className="row align-items-center g-0">
              <div className="col-xl-5">
                <div className="row">
                  <div className="col-md-7 mx-auto">
                    <Suspense
                      fallback={<Spinner color="text-primary" isPage={true} />}
                    >
                      <Outlet />
                    </Suspense>
                  </div>
                </div>
              </div>

              <div className="col-xl-7">
                <div className="account-page-bg p-md-5 p-4">
                  <div className="text-center">
                    <h3 className="text-dark mb-3 pera-title">
                      Quick, Effective, and Productive
                    </h3>
                    <div className="auth-image">
                      <img
                        src="../assets/images/receptionist.svg"
                        className="mx-auto img-fluid"
                        alt="images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
