import {
  Home,
  Heart,
  UserPlus,
  User,
  Clock,
  Briefcase,
  MessageSquare,
  Calendar,
  Users,
  ShoppingCart,
  Youtube,
  Mic,
  BookOpen,
  Image,
  Upload,
  Printer,
  Info,
  Rss,
} from 'feather-icons-react';
import { clerkAccess, memberAccess, roleObj } from './utils';

let _nav = [
  {
    menuItem: 'Dashboard',
    menuIcon: Home,
    menuLink: '/#/cyclux/dashboard',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Member',
    menuIcon: UserPlus,
    menuLink: '/#/cyclux/member',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Profile',
    menuIcon: User,
    menuLink: '/#/cyclux/profile',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Blog',
    menuIcon: Rss,
    menuLink: '/#/cyclux/blog',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Event',
    menuIcon: Clock,
    menuLink: '/#/cyclux/event',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Giving',
    menuIcon: Heart,
    menuLink: '/#/cyclux/giving',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Message',
    menuIcon: MessageSquare,
    menuLink: '/#/cyclux/message',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Calendar',
    menuIcon: Calendar,
    menuLink: '/#/cyclux/calendar',
    menuClass: 'tp-link',
  },
  // {
  //   menuItem: 'Finance',
  //   menuIcon: Briefcase,
  //   menuLink: 'finance',
  //   menuClass: 'collapse',
  // },
  // {
  //   menuItem: 'Group',
  //   menuIcon: Users,
  //   menuLink: 'group',
  //   menuClass: 'tp-link',
  // },
  // {
  //   menuItem: 'Store',
  //   menuIcon: ShoppingCart,
  //   menuLink: 'store',
  //   menuClass: 'tp-link',
  // },
  {
    menuTitle: 'Resources',
    menuTitleClass: 'menu-title',
  },
  {
    menuItem: 'Videos',
    menuIcon: Youtube,
    menuLink: '/#/cyclux/files',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Sermons',
    menuIcon: Mic,
    menuLink: '/#/cyclux/sermons',
    menuClass: 'tp-link',
  },
  // {
  //   menuItem: 'Library',
  //   menuIcon: BookOpen,
  //   menuLink: 'library',
  //   menuClass: 'tp-link',
  // },
  {
    menuItem: 'Gallery',
    menuIcon: Image,
    menuLink: '/#/cyclux/gallery',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Uploads',
    menuIcon: Upload,
    menuLink: '/#/cyclux/resource',
    menuClass: 'tp-link',
  },
  {
    menuTitle: 'Admin',
    menuTitleClass: 'menu-title',
  },
  {
    menuItem: 'People',
    menuIcon: UserPlus,
    menuLink: '/#/cyclux/people',
    menuClass: 'tp-link',
  },
  {
    menuItem: 'Attendees',
    menuIcon: Users,
    menuLink: '/#/cyclux/attendees',
    menuClass: 'tp-link',
  },
  // {
  //   menuItem: 'Report',
  //   menuIcon: Printer,
  //   menuLink: 'report',
  //   menuClass: 'tp-link',
  // },
  // {
  //   menuTitle: 'Audit',
  //   menuTitleClass: 'menu-title',
  // },
  // {
  //   menuItem: 'Audit Log',
  //   menuIcon: Info,
  //   menuLink: 'auditlog',
  //   menuClass: 'tp-link',
  // },
  // {
  //   menuItem: 'Profile',
  //   menuIcon: 'mdi mdi-account-outline',
  //   menuLink: '#sidebarProfile',
  //   menuToggleClass: 'collapse',
  //   menuArrowIcon: 'menu-arrow',
  //   subMenu: {
  //     id: 'sidebarProfile',
  //     divClass: 'collapse',
  //     navLevelClass: 'nav-second-level',
  //     menuItems: [
  //       {
  //         navLink: 'profile',
  //         navLinkClass: 'tp-link',
  //         navItem: 'Login',
  //       },
  //       {
  //         navLink: 'event',
  //         navLinkClass: 'tp-link',
  //         navItem: 'Register',
  //       },
  //     ],
  //   },
  // },
];

const user = JSON.parse(localStorage.getItem('user'));
const notMemberAccess = [1, 4, 6, 12, 13, 14, 15];
const notClerkAccess = [4, 6, 12, 13, 14];

if (memberAccess.includes(user?.role)) {
  _nav = _nav.filter((link, index) => !notMemberAccess.includes(index));
} else if (clerkAccess.includes(user?.role)) {
  _nav = _nav.filter((link, index) => !notClerkAccess.includes(index));
}

export default _nav;
