import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function SideBarNav({ items }) {
  const handleToggleSideBarMenu = () => {
    const body = document.body;
    const isMobileTablet = window.innerWidth <= 768;
    if (body.getAttribute('data-sidebar') == 'default' && isMobileTablet) {
      body.setAttribute('data-sidebar', 'hidden');
    } else {
      body.setAttribute('data-sidebar', 'default');
    }
  };

  const navItem = (item, index) => {
    const Icon = item.menuIcon;
    return (
      <li key={index}>
        <a
          href={item.menuLink}
          data-bs-toggle={item.menuToggleClass}
          onClick={handleToggleSideBarMenu}
        >
          <Icon />
          <span> {item.menuItem} </span>
          <span className={item.menuArrowIcon}></span>
        </a>
        {item.subMenu && (
          <div className={item.subMenu.divClass} id={item.subMenu.id}>
            <ul className={item.subMenu.navLevelClass}>
              {item.subMenu.menuItems &&
                item.subMenu.menuItems.map((subItem, index) => (
                  <li key={index}>
                    <Link
                      to={subItem.navLink}
                      className={subItem.navLinkClass}
                      onClick={handleToggleSideBarMenu}
                    >
                      {subItem.navItem}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </li>
    );
  };

  return items.map((item, index) =>
    item.menuTitleClass ? (
      <li key={index} className={item.menuTitleClass}>
        {item.menuTitle}
      </li>
    ) : (
      navItem(item, index)
    )
  );
}

export default SideBarNav;

SideBarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
